'use client';

import ErrorFallback from './error';

export default function GlobalError() {
	return (
		<html>
			<body>
				<ErrorFallback />
			</body>
		</html>
	);
}
